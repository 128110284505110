@import "../../../shared/styles/variable";
@import "../../../shared/styles/mixin";

.reseller-process__container {
  flex: 1;
  min-height: 90vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  // background-image: url("https://s3.ap-southeast-1.amazonaws.com/skydev-general.dev/background-solid.png");
  background-position: center;
  background-size: cover;
}

.reseller-process__card {
  max-width: 1200px;
  min-height: 491px;
  width: 100%;
  overflow: hidden;
  box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1);
  position: relative;
  border: 0 none;
  display: flex;
  flex-direction: column;
  padding: 24px;

  @media screen and (min-width: @screen-sm) {
    min-height: 450px;
  }

  & .ant-card-body {
    padding: 0;
    display: flex;
    flex: 1;
  }
}

.page-header {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  justify-content: space-between;
  @media screen and (max-width: @screen-xs-min) {
    // flex-direction: column;
    gap: 10px;
  }
  padding-left: 20%;
  padding-right: 20%;
}
// #wrapper-form {
//   width: 100%;
// }

// #wrapper-search {
//   width: 300px;
//   @media screen and (max-width: @screen-xs-min) {
//     width: 100%;
//   }
// }

.page-header-input-view {
  display: flex;

  #page-name {
    margin-right: 10px;
    max-width: 350px;
    width: 300px;
    @media screen and (min-width: @screen-xs) {
      max-width: 350px;
    }
  }
}

.page-header-right {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-left: auto;
  gap: 10px;

  [dir="rtl"] & {
    padding-left: 0;
    padding-right: 10px;
  }

  @media screen and (max-width: @screen-xs-min) {
    margin: 0;
    width: 100%;
  }
}

.success-screen__container {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  button {
    text-transform: uppercase;
  }
}

.success-screen__header {
  font-size: 24px;
  font-weight: 500;
}

.success-screen__content {
  font-size: 14px;
  text-align: center;
  max-width: 408px;
}

@primary-color: #0A8FDC;