@gl-btn-size: small, medium, large;
@gl-btn-type: primary, secondary, tertiary, danger, icon, link;

@gl-btn-bg-color: {
    primay: red
}

.gl-btn {
    // TODO: Reopen when use design system
    // position: relative;
    // display: inline-block;
    // white-space: nowrap;
    // text-align: center;
    // background-image: none;
    // border: 1px solid transparent;
    // cursor: pointer;
    // transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    // -webkit-user-select: none;
    // user-select: none;
    // touch-action: manipulation;
    // border-radius: 4px;

    // &:disabled,
    // &.disabled,
    // &[disabled] {

    //     &,
    //     &:hover,
    //     &:focus,
    //     &:active {
    //         cursor: not-allowed;
    //         text-shadow: none;
    //         box-shadow: none;
    //         background: @neutral-50;
    //         border-color: transparent;
    //         color: #BBBBBC;
    //     }
    // }
}

each(@gl-btn-size, {
    .gl-btn-@{value} {

        & when (@value =small) {
            height: @height-btn-small;
        }

        & when (@value =medium) {
            height: @height-btn-medium;
        }

        & when (@value =large) {
            height: @height-btn-large;
        }
    }
});


each(@gl-btn-type, {
    .gl-btn-@{value} {
        & when (@value =primary) {
            background-color: @primary-500;
            color: @white;

            &:hover {
                background-color: @primary-400;
                color: @white;
            }
        }

        & when (@value =secondary) {
            background-color: @primary-200;
            color: @primary-color;

            &:hover {
                background-color: @primary-500;
                color: @white;
            }

        }

        & when (@value =tertiary) {
            background-color: @white;
            color: @neutral-500;

            &:hover {
                background-color: @primary-500;
                color: @white;
            }

        }
    }

});