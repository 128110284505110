:root {
    --regular: 400;
    --medium: 500;
    --semi-bold: 600;
    --bold: 700;
    font-family: 'Roboto', serif;
}

body {
    line-height: 1.35 !important;
    font-family: 'Roboto', serif;
}

body>iframe {
    pointer-events: none;
}

* {
    margin: 0;
    padding: 0;
}

*,
*:before,
*:after {
    box-sizing: border-box;
}

img {
    // max-width: 100%;
    height: auto;
}