@import "../../../../shared/styles/variable";

.cr-user-info-avatar {
  font-size: 24px;
  background-color: @orange-6;
  width: 40px;
  min-width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;

  &.small {
    min-width: 30px;
    min-height: 30px;
    width: 30px;
    height: 30px;
    font-size: 16px;
  }
}

.user-action-info-wrapper {
  display: flex;
  gap: 10px;
  width: fit-content;
  align-items: center;

  .user-action-info-right-wrapper {
    display: flex;
    flex-direction: column;
    gap: 2px;
    text-align: left;

    .user-action-info-time-item {
      color: #9e9e9e;
    }
  }
}

.user-info-wrapper {
  display: flex;
  gap: 10px;
  width: 100%;
  align-items: center;

  .user-info-identity-wrapper {
    display: flex;
    flex-direction: column;
    gap: 2px;

    .user-info-time-item {
      color: #9e9e9e;
    }
  }

  .user-info-contact-wrapper {
    display: flex;
    flex-direction: column;
    gap: 2px;
    margin-left: auto;
    color: #0a6dd9;
    height: 100%;
    justify-content: flex-end;
    align-items: flex-end;
  }
}

@primary-color: #0A8FDC;