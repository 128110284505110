.gl-box {
    display: inline-block
}

.shadow-none {
    .box-shadow(@box-shadow-none)
}

.shadow-sm {
    .box-shadow(@box-shadow-sm)
}

.shadow-md {
    .box-shadow(@box-shadow-md)
}

.shadow-lg {
    .box-shadow(@box-shadow-lg)
}

.shadow-xl {
    .box-shadow(@box-shadow-xl);
}