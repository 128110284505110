.gl-input-wrap {
    height: 40px;

    &>.ant-input-prefix {
        margin-right: 6px;
    }
}

.gl-input-icon {
    color: @primary-color;
}
@primary-color: #0A8FDC;