.app-logo {
    display: flex;
    flex-direction: row;
    cursor: pointer;
    align-items: center;
    justify-content: center;
    background-color: transparent;
    gap: 10px;

    & img {
        height: 40px;

        [dir="rtl"] & {
            margin-right: 0;
            margin-left: 10px;
        }
    }
}

.app-logo-name {
    color: #66B91F;
    letter-spacing: 2px;
    margin-bottom: 0;
    text-transform: uppercase;
    // @media screen and (min-width: @screen-sm) {
    //     display: block;
    // }
}
@primary-color: #0A8FDC;