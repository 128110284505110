@selectors-weight: regular, medium, bold;
@selectors-size: xs, sm, md, lg;

.gl-typography-xs {
    font-size: @font-size-xs;
}

.gl-typography-sm {
    font-size: @font-size-sm;
}

.gl-typography-md {
    font-size: @font-size-md;
}

.gl-typography-lg {
    font-size: @font-size-lg;
}

.gl-typography-regular {
    .gl-typography(@font-weight-regular)
}

.gl-typography-medium {
    .gl-typography(@font-weight-medium)
}

.gl-typography-bold {
    .gl-typography(@font-weight-bold)
}

h1 {
    font-size: @heading-1-size;
}

h2 {
    font-size: @heading-2-size;
}

h3 {
    font-size: @heading-3-size;
}

h4 {
    font-size: @heading-4-size;
}

h5 {
    font-size: @heading-5-size;
}

h6 {
    font-size: @heading-6-size;
}


.gl-heading-bold {
    .gl-typography(@font-weight-bold);
}

.gl-heading-semi {
    .gl-typography(@font-weight-semi-bold);
}

.gl-typography (@weight) {
    font-weight: @weight;
    line-height: @line-height-typography;
}