// Box Shadow
.box-shadow(@shadow) {
    -webkit-box-shadow: @shadow;
    -moz-box-shadow: @shadow;
    box-shadow: @shadow;
}

// Single side box-sizing
.box-sizing(@type: border-box) {
    box-sizing: @type;
}

// Opacity
.opacity(@opacity: 0.5) {
    -moz-opacity: @opacity;
    opacity: @opacity;
}

// Transition
.transition(@transition) {
    -webkit-transition: @transition;
    -moz-transition: @transition;
    -ms-transition: @transition;
    -o-transition: @transition;
    transition: @transition;
}

// Rotate
.rotate(@deg) {
    -ms-transform: rotate(@deg);
    transform: rotate(@deg);
}

// Scale
.scale(@factor) {
    -ms-transform: scale(@factor);
    transform: scale(@factor);
}

// Translate
.translate(@factor, @factor) {
    -ms-transform: translate(@factor, @factor);
    transform: translate(@factor, @factor);
}

.translateY(@factor) {
    -ms-transform: translateY(@factor);
    transform: translateY(@factor);
}

.translateX(@factor) {
    -ms-transform: translateX(@factor);
    transform: translateX(@factor);
}

//Vertical Gradient
.vertical-gradient (@vstartColor: @orange-6, @vendColor: @red-6) {
    background-color: @vstartColor;
    background: -webkit-gradient(linear, left top, left bottom, from(@vstartColor), to(@vendColor));
    background: -webkit-linear-gradient(top, @vstartColor, @vendColor);
    background: -moz-linear-gradient(top, @vstartColor, @vendColor);
    background: -ms-linear-gradient(top, @vstartColor, @vendColor);
    background: -o-linear-gradient(top, @vstartColor, @vendColor);
}

//Horizontal Gradient
.horizontal-gradient (@hstartColor: @orange-6, @hendColor: @red-6) {
    background-color: @hstartColor;
    background: -webkit-gradient(linear, left top, right top, from(@hstartColor), to(@hendColor));
    background: -webkit-linear-gradient(left, @hstartColor, @hendColor);
    background: -moz-linear-gradient(left, @hstartColor, @hendColor);
    background: -ms-linear-gradient(left, @hstartColor, @hendColor);
    background: -o-linear-gradient(left, @hstartColor, @hendColor);
}

//Corner Left Gradient
.corner-left-gradient (@hstartColor: @cyan-6, @hendColor: @blue-8) {
    background: -moz-linear-gradient(125deg, @hstartColor 0%, @hendColor 100%);
    background: -webkit-gradient(linear, left top, right bottom, color-stop(0%, @hstartColor), color-stop(100%, @hendColor));
    background: -webkit-linear-gradient(125deg, @hstartColor 0%, @hendColor 100%);
    background: -o-linear-gradient(125deg, @hstartColor 0%, @hendColor 100%);
    background: -ms-linear-gradient(125deg, @hstartColor 0%, @hendColor 100%);
    background: linear-gradient(325deg, @hstartColor 0%, @hendColor 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='@hstartColor', endColorstr='@hendColor', GradientType=0);
}